<template>
  <vx-card class="overflow-hidden cursor-pointer"
           :card-background="bgColor"
           @click="$emit('tap')">
    <div slot="no-body">
      <div class="p-6 text-center">
        <!--                <feather-icon :icon="icon" class="p-3 inline-flex rounded-full" :class="[`text-${color}`, {'mb-4': !iconRight}]" :style="{background: `rgba(var(&#45;&#45;vs-${color}),.15)`}"></feather-icon>-->
        <span class="p-3 inline-flex rounded-full"
              :class="[`text-${color}`, ]"
              :style="{background: `rgba(var(--vs-${color}),0.15)`}">
            <strong>{{ title }}</strong>
            </span>
        <div class="py-3 truncate">
          <h1 class="font-bold text-custom"  :style="{color: `rgb(var(--vs-${textColor}))`}">{{ text }}</h1>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  props: {
    text: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary',
    },
    textColor: {
      type: String,
      default: 'primary',
    },
    bgColor: {
      type: String,
      default: 'primary',
    },
    colorTo: {
      type: String,
    },
  },
  data() {
    return {}
  },
  mounted() {
    // this.$vs.loading({
    //   container: `#loading-default`,
    //   type: "default",
    // })
  },
}
</script>
<style>
.loading-balance {
  height: 60px;
  width: 60px;
  margin: auto;
}
.text-custom {
  font-size: 30px;
}
</style>
