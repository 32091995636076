<template>
  <vx-card class="overflow-hidden">
    <div slot="no-body">
      <div class="p-6 text-center">
        <!--                <feather-icon :icon="icon" class="p-3 inline-flex rounded-full" :class="[`text-${color}`, {'mb-4': !iconRight}]" :style="{background: `rgba(var(&#45;&#45;vs-${color}),.15)`}"></feather-icon>-->
        <span class="p-3 inline-flex rounded-full"
              :class="[`text-${color}`, ]"
              :style="{background: `rgba(var(--vs-${color}),.15)`}">
            <strong>{{ title }}</strong>
            </span>
        <div v-if="text != '-'" class="py-3 truncate">
          <h2 class="mb-1 font-bold">{{ text }}</h2>
        </div>

        <!--        <div v-if="!text || text == '-'" class="activeLoading vs-con-loading__container loading-balance" id="loading-default"></div>-->
        <div v-if="text == '-'" class="activeLoading vs-con-loading__container loading-balance">
          <div class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined"><!----><!---->
            <div class="vs-loading default">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
              <img src=""><!----></div>
          </div>
          <div class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined"><!----><!---->
            <div class="vs-loading default">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
              <img src=""><!----></div>
          </div>
          <div class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined"><!----><!---->
            <div class="vs-loading default">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
              <img src=""><!----></div>
          </div>
          <div class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined"><!----><!---->
            <div class="vs-loading default">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
              <img src=""><!----></div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  props: {
    text: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary',
    },
    colorTo: {
      type: String,
    },
  },
  data() {
    return {}
  },
  mounted() {
    // this.$vs.loading({
    //   container: `#loading-default`,
    //   type: "default",
    // })
  },
}
</script>
<style>
.loading-balance {
  height: 60px;
  width: 60px;
  margin: auto;
}
</style>
